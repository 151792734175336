<template>
  <img
    v-if="reg?.logo"
    class="logo"
    :alt="$t('ui.logo')"
    :src="require(`@/assets/${reg.logo}`)"
  >
  <h1
    v-if="!reg"
    class="title"
  >
    {{ $t('clientError.veriFailed') }}
  </h1>
  <BaseInfobox
    v-if="msg"
    :type="infoboxType"
    v-html="msg"
  />
  <p
    v-if="reg"
    v-html="reg.verifiedMsg"
  />
</template>

<script>
import BaseInfobox from '@/components/BaseInfobox.vue'
import VerificationService from '@/services/VerificationService.js'
import { setLocale } from '@/util/i18n.js'

export default {
  name: 'VerificationPage',
  components: {
    BaseInfobox
  },
  data () {
    return {
      reg: null,
      msg: '',
      infoboxType: ''
    }
  },
  async created () {
    setLocale(this.$route.query.lang)

    const params = this.$route.params
    if (this.isTokenCorrectlyFormatted(params.veriToken)) {
      const response = await VerificationService.checkVerification(params.veriToken)
      this.reg = response.reg
      this.msg = this.$t(response.msg)
      this.infoboxType = response.reg ? 'success' : 'error'
    } else {
      this.msg = this.$t('clientError.tokenImproperlyFormatted')
      this.infoboxType = 'error'
    }
  },
  methods: {
    isTokenCorrectlyFormatted (token) {
      const re = /[A-Za-z0-9]{32}/
      return re.test(token)
    }
  }
}
</script>
