import http from '@/services'
import i18n from '@/i18n.js'

export default {
  async checkVerification (token) {
    try {
      const response = await http.get(`verification.cfc?method=checkVerification&token=${token}&lang=${i18n.global.locale}`)
      return {
        reg: response.data.reg,
        msg: response.data.msg
      }
    } catch (error) {
      return {
        reg: null,
        msg: error?.response?.data?.msg || 'serverError.unknownServerError'
      }
    }
  }
}
